import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceUserSettings {

    static getUsersSettingsGeneralInfoByUserId = async (userId) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/UserSettings/GetUsersSettingsGeneralInfoByUserId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                userId: userId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static updateUserSettingsGeneral = async (model) => {
        let returnValue = false;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/UserSettings/UpdateUsersSettingsGeneral', JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/json',
               Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUsersSettingsPagesByUserId = async (userId, page, userSettingName) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/UserSettings/GetUsersSettingsPagesByUserId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                userId: userId,
                page: page,
                userSettingName: userSettingName
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static updateUsersSettingsPagesUserSetting = async (model) => {
        let returnValue = false;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/UserSettings/UpdateUsersSettingsPagesUserSetting', JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            }
        })
            .then(function (response) {
                const userSettingsPages = JSON.parse(localStorage.getItem('userSettingsPages'));
                var index = userSettingsPages.findIndex(s => s.userId === model.userId && s.page === model.page && s.userSettingName === model.userSettingName);
                userSettingsPages[index].valueBit = model.valueBit;
                userSettingsPages[index].valueString = model.valueString;
                userSettingsPages[index].valueInt = model.valueInt;
                userSettingsPages[index].valueDecimal = model.valueDecimal;
                localStorage.setItem('userSettingsPages', JSON.stringify(userSettingsPages));

                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceUserSettings;
