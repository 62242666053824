import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar} from 'devextreme-react/data-grid';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { Icon } from '@iconify/react';

import HxDialog from '../../../../custom-components/hx-dialog/HxDialog'
import HxPopupExportExcel from '../../../../custom-components/hx-popup-excel-export/HxPopupExportExcel'
import HxToast from '../../../../custom-components/hx-toast/HxToast';

import globalConfig from '../../../../../config/global.js';
import globalIcons from '../../../../../config/globalIcons.js';

import PopupProducts from './PopupProducts';

import ServiceProducts from '../../../../../api/services/ServiceProducts';

import './DataGridProducts.scss'

// callables
//
// callbacks
//      onInitialized
//      onDBError

class DataGridProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceProducts: [],
            showFilter: false,
            useAdvancedFilter: false,
        }

        this.gridInstance = null;
        this.popupInstance = null;
        this.popupExportExcelInstance = null;

        this.allowedPageSizes = [10, 20, 50];

        this.hubConnection = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    // CALLABLES



    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceProducts(true);
        this.hubInit();
    }

    // HUB
    hubInit = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(globalConfig.settings.apihost + "/productsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessage", await this.hubReceiveMessage);
    }

    hubReceiveMessage = async () => {
        await this.loadDataSourceProducts(false);
    }

    hubSendUpdate = async () => {
        this.hubConnection
            .invoke("SendUpdate")
            .catch(async function (err) {
                await this.loadDataSourceProducts();
                return console.error(err.toString());
            });
    }

    // DATA
    loadDataSourceProducts = async (showLoader) => {
        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.beginCustomLoading();
        }

        var data = await ServiceProducts.getProductsInfo();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceProducts: data });
        } else {
            this.onDBError('Database error!');  // callback
        }

        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.endCustomLoading();
        }
    }

    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnCellClick = async (e) => {
        // if white space is clicked then e.column is undefined
        if (e.rowIndex === -1) {
            return;
        }

        if (e.rowType === 'header') {
            if (e.column.name.toUpperCase() === 'ACTIONCOLUMNCREATEUPDATE') {
                await this.refPopup.preload(-1);
                this.popupInstance.show();
            }
        } else if (e.rowType === 'data') {
            switch (e.column.name.toUpperCase()) {
                case 'ACTIONCOLUMNCREATEUPDATE':
                    await this.refPopup.preload(e.data.id);
                    this.popupInstance.show();
                    break;
                case 'ACTIONCOLUMNDELETE':
                    var data = await ServiceProducts.canProductBeDeleted(e.data.id);
                    if (!data.hasOwnProperty('error')) {
                        if (data === true) {
                            var result = await HxDialog.showDialogYesNo('Delete ' + e.data.productName + '?', 'Confirm delete')
                            if (result === true) {
                                await this.deleteProduct(e.data.id);
                            }
                        }
                    } else {
                        this.onDBError('Database error!');  // callback
                    }
                    break;
                default:
                    break;
            }
        }
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'locationName' || e.column.dataField === 'productName') {
                e.cellElement.style.backgroundColor = e.data.colorHex;
                e.cellElement.style.color = e.data.foreColorHex;
            }
        } 
    }

    gridOnRowDblClick = async (e) => {
        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        if (userSettingsGeneral.allowDoubleClickOnGrid === true) {
            await this.refPopup.preload(e.data.id);
            this.popupInstance.show();
        }
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.excel,
                onClick: this.exportProduct
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.filter,
                onClick: this.switchFilter
            },
            location: 'after'
        });
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.advanced_filter,
                onClick: this.switchAdvancedFilter
            },
            location: 'after'
        });
    }

    deleteProduct = async (id) => {
        var resultProduct = await ServiceProducts.deleteProduct(id);
        if (!resultProduct.hasOwnProperty("error")) {
            if (resultProduct === true) {
                await this.hubSendUpdate();
                HxToast.showToast('Product deleted', 'success', 'top right', 'up-stack');
            } else {
                HxToast.showToast('Delete product failed!', 'error', 'top right', 'up-stack');
            }
        } else {
            this.onDBError('Database error!'); // callback
            HxToast.showToast('Delete product failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP CREATE/UPDATE
    popup_onInitialized = (instance) => {
        this.popupInstance = instance;
    }

    popup_onDBError = (message) => {
        this.onDBError(message)
    }

    popup_OnCreate = async (id) => {
        if (id !== -1) {
            await this.hubSendUpdate();
            HxToast.showToast('Product created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create product failed!', 'error', 'top right', 'up-stack');
        }
    }

    popup_OnUpdate = async (success) => {
        if (success === true) {
            await this.hubSendUpdate();
            HxToast.showToast('Product updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update product failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP EXPORT
    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.exportToExcel(fileName);
        }
    }

    exportProduct = () => {
        this.popupExportExcelInstance.show();
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Product', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }
        });

        worksheet.pageSetup.margins = {
            left: 0.3, right: 0.3,
            top: 0.3, bottom: 0.3,
            header: 0.3, footer: 0.3
        };

        exportDataGrid({
            component: this.gridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: true,
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
        });
    }

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header') {
            excelCell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        }
    }


    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    switchAdvancedFilter = () => {
        this.setState(prevState => ({
            useAdvancedFilter: !prevState.useAdvancedFilter
        }));
        this.gridInstance.repaint();
    }

    // RENDERING
    renderCreateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-14px', position: 'absolute', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:plus-thick" style={{ color: '#03a9f4', fontSize: '16px' }} /></div>
        </div>;
    }

    renderUpdateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-3px', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:pencil" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.canBeDeleted === true) {
            return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
                <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#03a9f4' }} /></div>
            </div>;
        }
        return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
            <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#999999' }} /></div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <div className="dgp-main-div">
                    <div className='dx-card dgp-grid'>
                        <div className='dgp-grid-title'>Products</div>
                        <div className="dgp-grid-div">
                            <DataGrid
                                ref={ref => this.refGrid = ref}
                                dataSource={this.state.dataSourceProducts}
                                keyExpr='id'
                                height='100%'
                                allowColumnReordering={false}
                                allowColumnResizing={false}
                                cacheEnabled={true}
                                columnAutoWidth={false}
                                columnResizingMode='widget'
                                filterSyncEnabled={true}
                                focusedRowEnabled={false}
                                hoverStateEnabled={true}
                                noDataText='No products found.'
                                remoteOperations={true}
                                repaintChangesOnly={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={false}
                                onInitialized={this.gridOnInitialized}
                                onCellClick={this.gridOnCellClick}
                                onCellPrepared={this.gridOnCellPrepared}
                                onRowDblClick={this.gridOnRowDblClick}
                                onToolbarPreparing={this.gridOnToolbarPreparing}
                            >
                                <StateStoring enabled={false} type="localStorage" storageKey="storageProductsDataGrid" />
                                <Sorting mode='multiple' />
                                <ColumnChooser enabled={false} />
                                <Paging enabled={true} defaultPageSize={20} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={this.allowedPageSizes}
                                    showNavigationButtons={true}
                                />
                                <FilterPanel visible={this.state.useAdvancedFilter} />
                                <FilterRow visible={this.state.showFilter} />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} searchVisibleColumnsOnly={true} />
                                <Scrolling
                                    mode="standard"
                                    columnRenderingMode="standard"
                                    rowRenderingMode="standard"
                                    showScrollBar='onHover'
                                    preloadEnabled={true}
                                />
                                <Column
                                    dataField='id'
                                    dataType='number'
                                    caption='Id'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='colorId'
                                    dataType='number'
                                    caption='ColorId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='colorHex'
                                    dataType='string'
                                    caption='ColorHex'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='foreColorHex'
                                    dataType='string'
                                    caption='ForeColorHex'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='canbedeleted'
                                    dataType='number'
                                    caption=''
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    name='ActionColumnCreateUpdate'
                                    caption=''
                                    allowEditing={false}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    width='30px'
                                    alignment='center'
                                    showInColumnChooser={false}
                                    cellRender={this.renderUpdateTemplate}
                                    headerCellRender={this.renderCreateTemplate} />
                                <Column
                                    dataField='productName'
                                    dataType='string'
                                    caption='Product'
                                    width='400px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    allowSorting={true}
                                    cssClass='wrapped-column-class'
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='locationName'
                                    dataType='string'
                                    caption='Shop'
                                    width='250px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    cssClass='wrapped-column-class'
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='packaging'
                                    dataType='string'
                                    caption='Packaging'
                                    width='250px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='articleNo'
                                    dataType='string'
                                    caption='Article No'
                                    width='250px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    name='ActionColumnDelete'
                                    caption=''
                                    allowEditing={false}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    width='30px'
                                    alignment='center'
                                    showInColumnChooser={false}
                                    cellRender={this.renderDeleteTemplate}
                                />
                                <Column key='productcoldummy' name='dummycol' caption='' allowFiltering={false} allowResizing={true} allowExporting={false} width='100%' />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <PopupProducts
                    ref={ref => this.refPopup = ref}
                    onInitialized={this.popup_onInitialized}
                    onDBError={this.popup_onDBError}
                    onCreate={this.popup_OnCreate}
                    onUpdate={this.popup_OnUpdate}
                />
                <HxPopupExportExcel
                    defaultName='Product'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />
            </React.Fragment>
        );

    }
}

export default DataGridProducts

