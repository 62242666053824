import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popover } from 'devextreme-react/popover';
import RadioGroup from 'devextreme-react/radio-group';
import { Tooltip } from 'devextreme-react/tooltip';
import { on } from "devextreme/events";

import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { Icon } from '@iconify/react';

import HxDialog from '../../custom-components/hx-dialog/HxDialog'
import HxPopupExportExcel from '../../custom-components/hx-popup-excel-export/HxPopupExportExcel'
import HxToast from '../../custom-components/hx-toast/HxToast';

import PopupShuttlesEmail from './PopupShuttlesEmail';
import PopupPrintDriverSheet from './PopupPrintDriverSheet';

import globalConfig from '../../../config/global.js';
import globalIcons from '../../../config/globalIcons.js';

import PopupShuttles from './PopupShuttles';

import ServiceShuttles from '../../../api/services/ServiceShuttles';

import './DataGridShuttles.scss'

// props
//
// callables
//
// callbacks
//      onInitialized
//      onAddTrip
//      onUpdateTrip
//      onDeleteTrip
//      onDBError
//      onDataLoaded

const startDateRange = new Date('2022-06-18T05:00:00.000');
const endDateRange = new Date('2022-06-19T05:00:00.000');
const startHour = 5;
const paintOffset = 5;

class DataGridShuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceTrips: [],
            tripStatusses: [],
            toolTipData: null,
            showFilter: false,
            useAdvancedFilter: false,
        }

        this.currentGigDateId = -1;

        this.gridInstance = null;
        this.popupInstance = null;
        this.popupExportExcelInstance = null;
        this.popupPrintDriverSheetInstance = null;
        this.popupShuttlesEmailInstance = null;

        this.popOverStatussesInstance = null;
        this.radioGroupPopOverStatussesInstance = null;

        this.tripStatussesData = [];
        this.processRadiogroupStatussesValueChanged = false;
        this.selectedTripIdForStatus = -1;

        this.showTimeLineSwitch = true;

        this.colWidthTimings = 600;

        this.hubConnection = null;

        window.addEventListener('resize', this.handleResize);
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onAddTrip = () => {
        if (this.props.onAddTrip) {
            this.props.onAddTrip();  // callback
        }
    }

    onUpdateTrip = () => {
        if (this.props.onUpdateTrip) {
            this.props.onUpdateTrip();  // callback
        }
    }

    onDeleteTrip = () => {
        if (this.props.onDeleteTrip) {
            this.props.onDeleteTrip();  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onDataLoaded = (gigDateId, tripsCount) => {
        if (this.props.onDataLoaded) {
            this.props.onDataLoaded(gigDateId, tripsCount);  // callback
        }
    }

    // CALLABLES
    reload = async (gigDateId) => {
        this.currentGigDateId = gigDateId;
        await this.loadDataSourceTrips(gigDateId);
        this.gridOnContentReady();
    }

    setTripStatusses = (tripStatusses) => {
        this.tripStatussesData = tripStatusses;
        const ts = this.tripStatussesData.map(function (item) { return item.statusName });
        this.setState({ tripStatusses: ts });
    }

    setStatusFilter(showStatusWaiting, showStatusDriving, showStatusFinished, showStatusCancelled) {
        let filter = [];

        if (showStatusWaiting) {
            filter.push(["statusName", "=", 'Waiting']);
        }

        if (showStatusDriving) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["statusName", "=", 'Driving']);
        }

        if (showStatusFinished) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["statusName", "=", 'Finished']);
        }

        if (showStatusCancelled) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["statusName", "=", 'Cancelled']);
        }

        this.gridInstance.filter(filter);
    }

    showHideTimeLine = (showHide) => {
        this.showTimeLineSwitch = showHide;
        this.repaintGrid();
    }

    // EVENTS
    componentDidMount = async () => {
        this.hubInit();
    }

    // HUB
    hubInit = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(globalConfig.settings.apihost + "/shuttlesHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessage", await this.hubReceiveMessage);
    }

    hubReceiveMessage = async () => {
        await this.loadDataSourceTrips(this.currentGigDateId, false);
        setTimeout(this.repaintGrid, 200);
    }

    hubSendUpdate = async () => {
        var _self = this;
        this.hubConnection
            .invoke("SendUpdate")
            .catch(async function (err) {
                await _self.loadDataSourceTrips(_self.currentGigDateId);
                return console.error(err.toString());
            });
    }

    // DATA
    loadDataSourceTrips = async (gigDateId, showLoader) => {
        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.beginCustomLoading();
        }

        var data = await ServiceShuttles.getTripsInfoByGigDateId(gigDateId, globalConfig.settings.shuttles.startHour);
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceTrips: data });
            this.onDataLoaded(this.currentGigDateId, data.length);  // callback
        } else {
            this.onDBError('Database error!');  // callback
        }

        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.endCustomLoading();
        }
    }

    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnCellClick = async (e) => {
        // if white space is clicked then e.column is undefined
        if (e.rowIndex === -1) {
            return;
        }

        if (e.rowType === 'header') {
            switch (e.column.name.toUpperCase()) {
                case 'ACTIONCOLUMNCREATEUPDATE':
                    await this.refPopup.preload(-1);
                    this.popupInstance.show();
                    break;
                case 'COLUMNTIMINGS':
                    const sortOrder = this.gridInstance.columnOption('dateTimeDeparture', 'sortOrder');
                    if (sortOrder === undefined) {
                        this.gridInstance.columnOption('dateTimeDeparture', 'sortOrder', 'asc');
                    } else {
                        switch (sortOrder.toUpperCase()) {
                            case 'ASC':
                                this.gridInstance.columnOption('dateTimeDeparture', 'sortOrder', 'desc');
                                break;
                            case 'DESC':
                                this.gridInstance.columnOption('dateTimeDeparture', 'sortOrder', undefined);
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                default:
                    break;
            }
        } else if (e.rowType === 'data') {
            switch (e.column.name.toUpperCase()) {
                case 'ACTIONCOLUMNCREATEUPDATE':
                    await this.refPopup.preload(e.data.id);
                    this.popupInstance.show();
                    break;
                case 'ACTIONCOLUMNDELETE':
                    var result = await HxDialog.showDialogYesNo('Delete ' + e.data.LocationDepartureName + ' - ' + e.data.LocationDepartureName + ' - ' + e.data.LocationDepartureName + '?', 'Confirm delete')
                    if (result === true) {
                        await this.deleteShuttle(e.data.id);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    gridOnRowDblClick = async (e) => {
        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        if (userSettingsGeneral.allowDoubleClickOnGrid === true) {
            await this.refPopup.preload(e.data.id);
            this.popupInstance.show();
        }
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.excel,
                onClick: this.exportShuttles
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.filter,
                onClick: this.switchFilter
            },
            location: 'after'
        });
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.advanced_filter,
                onClick: this.switchAdvancedFilter
            },
            location: 'after'
        });
    }

    gridOnContentReady = () => {
        this.colWidthTimings = (document.getElementById('divGrid') !== null ? document.getElementById('divGrid').clientWidth - 648 : 600);
        setTimeout(this.drawHeaderTiming, 100);  // WHY???? not a clue
    }

    gridOnCellPrepared = (e) => {
        e.cellElement.style.paddingTop = 0;
        e.cellElement.style.paddingBottom = 0;

        if (e.rowType === "data" && e.column.name === 'columnTimings') {
            const urgency = this.checkUrgency(e.data);
            if (urgency !== 'NOCOLOR') {
                e.cellElement.style.backgroundColor = urgency;
            }

            on(e.cellElement.children[0].childNodes[0], "mouseover", arg => {
                this.setState({
                    toolTipData: e.data
                });
                this.refTooltip.instance.show(arg.target);
            });

            on(e.cellElement.children[0].childNodes[0], "mouseout", arg => {
                this.refTooltip.instance.hide();
            });
        }
    }

    getTiming = () => {
        const startDate = new Date(this.state.toolTipData.dateTimeDeparture);
        const endDate = new Date(this.state.toolTipData.dateTimeArrival);
        return startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0') + ' - ' + endDate.getHours().toString().padStart(2, '0') + ':' + endDate.getMinutes().toString().padStart(2, '0')
    }

    checkUrgency = (data) => {
        let bg = "NOCOLOR";
        if (data.statusName.toLowerCase() === 'waiting') {
            const dtDep = new Date(data.dateTimeDeparture);
            const dtNow = new Date();
            if (dtDep.getFullYear() === dtNow.getFullYear() && dtDep.getMonth() === dtNow.getMonth() && dtDep.getDate() === dtNow.getDate()) {
                let diffMinutes = (dtDep.getHours() * 60 + dtDep.getMinutes()) - (dtNow.getHours() * 60 + dtNow.getMinutes())
                if (diffMinutes > 0 && diffMinutes <= 15) {
                    if (diffMinutes <= 5) {
                        bg = '#FF0000';
                    } else {
                        bg = '#FFA500';
                    }
                } else if (diffMinutes <= 0) {
                    bg = '#FF0000';
                }
            }
        }

        return bg;
    }

    deleteShuttle = async (id) => {
        var resultShuttles = await ServiceShuttles.deleteTrip(id);
        if (!resultShuttles.hasOwnProperty("error")) {
            if (resultShuttles === true) {
                await this.hubSendUpdate();
                HxToast.showToast('Shuttle deleted', 'success', 'top right', 'up-stack');
            } else {
                HxToast.showToast('Delete shuttle failed!', 'error', 'top right', 'up-stack');
            }
        } else {
            this.onDBError('Database error!'); // callback
            HxToast.showToast('Delete shuttle failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP CREATE/UPDATE
    popup_onInitialized = (instance) => {
        this.popupInstance = instance;
    }

    popup_onDBError = (message) => {
        this.onDBError(message)
    }

    popup_OnCreate = async (id) => {
        if (id !== -1) {
            await this.hubSendUpdate();
            HxToast.showToast('Shuttle created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create shuttle failed!', 'error', 'top right', 'up-stack');
        }
    }

    popup_OnUpdate = async (success) => {
        if (success === true) {
            await this.hubSendUpdate();
            HxToast.showToast('Shuttle updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update shuttle failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP DRIVERSHEET
    popupPrintDriverSheet_OnInitialized = (instance) => {
        this.popupPrintDriverSheetInstance = instance;
    }

    // POPUP Email
    popupShuttlesEmail_OnInitialized = (instance) => {
        this.popupShuttlesEmailInstance = instance;
    }

    // POPOVER STATUSSES
    popOverStatusses_OnInitialized = (e) => {
        this.popOverStatussesInstance = e.component;
    }

    // RADIOGROUP STATUSSES
    radioGroupPopOverStatusses_Initialized = (e) => {
        this.radioGroupPopOverStatussesInstance = e.component;
    }

    radioGroupPopOverStatusses_OnValueChanged = async (e) => {
        if (!this.processRadiogroupStatussesValueChanged) {
            this.processRadiogroupStatussesValueChanged = true;
            return;
        }

        const statusName = this.radioGroupPopOverStatussesInstance.option('value');
        const statusId = this.tripStatussesData.find(x => x.statusName === statusName).id;

        if (statusId !== undefined) {
            var data = await ServiceShuttles.updateTripStatusForTrip(this.selectedTripIdForStatus, statusId);
            if (data !== null && !data.hasOwnProperty("error") && data === true) {
                await this.hubSendUpdate();
                setTimeout(this.repaintGrid, 200);
            } else {
                this.onDBError('Database error!');  // callback
            }
        } else {
            this.onDBError('Unknown error!');  // callback
        }

        this.popOverStatussesInstance.hide();

    }


    // POPUP EXPORT
    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.exportToExcel(fileName);
        }
    }

    exportShuttles = () => {
        this.popupExportExcelInstance.show();
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Locations', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }
        });

        worksheet.pageSetup.margins = {
            left: 0.3, right: 0.3,
            top: 0.3, bottom: 0.3,
            header: 0.3, footer: 0.3
        };

        exportDataGrid({
            component: this.gridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: true,
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
        });
    }

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header') {
            excelCell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        }
    }


    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    switchAdvancedFilter = () => {
        this.setState(prevState => ({
            useAdvancedFilter: !prevState.useAdvancedFilter
        }));
        this.repaintGrid();
    }

    // RENDERING
    handleResize = () => {
        try {
            this.repaintGrid()
        } catch (e) {
            console.log("error repainting grid")
        }
    }

    repaintGrid = () => {
        this.gridInstance.beginUpdate()
        this.gridInstance.repaint();
        this.gridInstance.endUpdate()
    }

    renderCreateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-14px', position: 'absolute', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:plus-thick" style={{ color: '#03a9f4', fontSize: '16px' }} /></div>
        </div>;
    }

    renderUpdateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-3px', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:pencil" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
            <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    renderTimingsHeader = (e) => {
        if (e.rowType === 'header' && e.column.name === 'columnTimings') {
           // const colWidth = (document.getElementById('divGrid') !== null ? document.getElementById('divGrid').clientWidth - 648 : 600);

            return <div className="sdt-timings-header">
                <canvas id='canvasTimingsHeader' className="sdt-canvas-timings-header" width={this.colWidthTimings} height='25px'></canvas>
            </div>;
        }
    }

    drawHeaderTiming = () => {
        var canvas = document.getElementById("canvasTimingsHeader");
        if (canvas !== null) {
            const colWidth = this.colWidthTimings;
            const diffHours = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 3600000;
            const hourPixelWidth = (colWidth / diffHours);
            const diffMinutes = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 60000;
            const minutePixelWidth = (colWidth / diffMinutes);

            let hour = startHour;
            let ctx = canvas.getContext("2d");
            ctx.font = "13px Roboto,RobotoFallback,'Noto Kufi Arabic',Helvetica,Arial,sans-serif";
            ctx.lineWidth = 1;
            ctx.fillStyle = "#999999";
            ctx.strokeStyle = '#999999';

            let offset = paintOffset;
            for (var i = startHour; i < 24; i++) {
                ctx.beginPath();
                ctx.moveTo(offset, 0);
                ctx.lineTo(offset, 8);
                ctx.stroke();
                ctx.fillText(hour, (i < 10 ? offset - 4 : offset - 8), 20);
                ctx.closePath();

                offset += hourPixelWidth;
                hour += 1;
            }

            hour = 0;
            for (var i = 0; i <= startHour; i++) {
                ctx.beginPath();
                ctx.moveTo(offset, 0);
                ctx.lineTo(offset, 8);
                ctx.stroke();
                ctx.fillText(hour, (i < 10 ? offset - 4 : offset - 8), 20);
                ctx.closePath();

                offset += hourPixelWidth;
                hour += 1;
            }

            if (this.showTimeLineSwitch) {
                ctx.beginPath();
                ctx.strokeStyle = '#ff0000';
                var dtNow = new Date();
                const convStartDateBG = new Date(new Date(new Date(new Date(startDateRange).setDate(dtNow.getDate())).setMonth(dtNow.getMonth())).setFullYear(dtNow.getFullYear()));
                const offsetLeftBG = ((Math.abs(dtNow.getTime() - convStartDateBG.getTime()) / 60000) * minutePixelWidth) + paintOffset + 3;
                ctx.moveTo(offsetLeftBG - 3, 0);
                ctx.lineTo(offsetLeftBG + 3, 0);
                ctx.lineTo(offsetLeftBG, 6);
                ctx.lineTo(offsetLeftBG - 3, 0);
                // ctx.lineTo(offsetLeftBG, 36);
                ctx.stroke();
                ctx.fillStyle = "#FF0000";
                ctx.fill();
                ctx.closePath();
            }
        }
    }

    renderTimingsTemplate = (e) => {
        // const colWidth = (document.getElementById('divGrid') !== null ? document.getElementById('divGrid').clientWidth - 648 : 600);
        const colWidth = this.colWidthTimings;
        const diffHours = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 3600000;
        const diffMinutes = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 60000;
        const hourPixelWidth = (colWidth / diffHours);
        const minutePixelWidth = (colWidth / diffMinutes);

        const startTime = new Date(e.data.dateTimeDeparture);
        const endTime = new Date(e.data.dateTimeArrival);

        // nogal rare constructie, maar werkt
        let convStartDate = new Date();
        let offsetLeft = 0;
        let divWidth = 0;

        if (startTime.getHours() >= startHour) {
            convStartDate = new Date(new Date(new Date(new Date(startDateRange).setDate(startTime.getDate())).setMonth(startTime.getMonth())).setFullYear(startTime.getFullYear()));
            offsetLeft = ((Math.abs(startTime.getTime() - convStartDate.getTime()) / 60000) * minutePixelWidth) + paintOffset + 3;
            divWidth = (Math.abs(endTime.getTime() - startTime.getTime()) / 60000) * minutePixelWidth;
        } else {
            convStartDate = new Date(new Date(new Date(new Date(startTime).setHours(0)).setMinutes(0)).setSeconds(0));
            offsetLeft = (hourPixelWidth * (24 - startHour)) + ((Math.abs(startTime.getTime() - convStartDate.getTime()) / 60000) * minutePixelWidth) + paintOffset + 3;
            divWidth = (Math.abs(endTime.getTime() - startTime.getTime()) / 60000) * minutePixelWidth;
        }

        let css = {};
        css.left = offsetLeft;
        css.width = divWidth;
        css.position = 'relative';

        const searchRegExp = /\[rgba\]/gi;
        var patternColor = (e.data.patternColorHex !== null ? e.data.patternColorHex : '#00000033');
        if (e.data.cssBackGround !== null) {
            css.background = e.data.cssBackGround.replace(searchRegExp, patternColor);
        }
        if (e.data.cssBackGroundImage !== null) {
            css.backgroundImage = e.data.cssBackGroundImage.replace(searchRegExp, patternColor);
        }
        if (e.data.cssBackGroundSize !== null) {
            css.backgroundSize = e.data.cssBackGroundSize;
        }
        if (e.data.cssBackGroundPosition !== null) {
            css.backgroundPosition = e.data.cssBackGroundPosition;
        }

        if (e.data.colorHex !== null) {
            css.backgroundColor = e.data.colorHex;
        }
        if (e.data.foreColorHex !== null) {
            css.color = e.data.foreColorHex;
        }

        css.height = '20px';
        css.display = 'flex';
        css.justifyContent = 'center';
        css.alignItems = 'center';
        css.zIndex = 99;

        let cssStatus = {};
        cssStatus.left = offsetLeft + 10;
        cssStatus.width = '20px';
        cssStatus.position = 'relative';
        cssStatus.zIndex = 99;
        cssStatus.height = '20px';
        cssStatus.display = 'flex';
        cssStatus.justifyContent = 'center';
        cssStatus.alignItems = 'center';

        let className = "";
        switch (e.data.statusName.toUpperCase()) {
            case "WAITING":
                className = "dx-icon-clock"
                cssStatus.display = 'flex';
                break;
            case "DRIVING":
                className = "dx-icon-car"
                cssStatus.display = 'flex';
                break;
            case "FINISHED":
                className = "dx-icon-check"
                cssStatus.display = 'flex';
                break;
            case "CANCELLED":
                className = "dx-icon-minus"
                cssStatus.display = 'flex';
                break;
            default:
        }

        const divId = "status-" + e.data.id;
        if (this.showTimeLineSwitch) {
            var dtNow = new Date();
            const convStartDateBG = new Date(new Date(new Date(new Date(startDateRange).setDate(dtNow.getDate())).setMonth(dtNow.getMonth())).setFullYear(dtNow.getFullYear()));
            const offsetLeftBG = ((Math.abs(dtNow.getTime() - convStartDateBG.getTime()) / 60000) * minutePixelWidth) + paintOffset  + 3;
            let cssTimeLine = {};
            cssTimeLine.left = -divWidth + offsetLeftBG;
            cssTimeLine.width = '2px';
            cssTimeLine.position = 'relative';
            cssTimeLine.backgroundColor = '#FF0000AA';
            cssTimeLine.zIndex = 100;
            cssTimeLine.height = '32px'
            cssTimeLine.top = '-1px';

            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <div style={css}>{e.data.driverName}</div>
                <div style={cssTimeLine}></div>
                <div id={divId} style={cssStatus} onClick={() => this.showStatussesPopup(divId, e.data.id, e.data.statusName)}><span style={{ width: '40px' }}><i className={className} style={{ color: '#000000' }} /></span></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <div style={css}>{e.data.driverName}</div>
                <div id={divId} style={cssStatus} onClick={() => this.showStatussesPopup(divId, e.data.id, e.data.statusName)}><span style={{ width: '40px' }}><i className={className} style={{ color: '#000000' }} /></span></div>
            </div>;
        }
    }

    renderDriverSheetTemplate = (e) => {
        return <Button
            icon='exportpdf'
            stylingMode='text'
            text=''
            onClick={async () => {
                await this.refPopupPrintDriverSheet.preload(e.data);
                this.popupPrintDriverSheetInstance.show();
            }}
        />;
    }

    renderCreateEmailTemplate = (e) => {
        return <Button
            icon='email'
            stylingMode='text'
            text=''
            onClick={async () => {
                await this.refPopupShuttlesEmail.preload(e.data.id, e.data.artistId);
                this.popupShuttlesEmailInstance.show();
            }}
        />;
    }
    showStatussesPopup = (divId, tripId, statusName) => {
        this.selectedTripIdForStatus = tripId;
        this.popOverStatussesInstance.option('target', "#" + divId);
        this.popOverStatussesInstance.option('title', "Status");
        this.processRadiogroupStatussesValueChanged = false;
        this.radioGroupPopOverStatussesInstance.option('value', statusName);
        this.processRadiogroupStatussesValueChanged = true;
    }

    render() {
        return (
            <React.Fragment>
                <div className="dgs-main-div">
                    <div className='dx-card dgs-grid'>
                        <div id="divGrid" className="dgs-grid-div">
                            <DataGrid
                                ref={ref => this.refGrid = ref}
                                dataSource={this.state.dataSourceTrips}
                                keyExpr='id'
                                height='100%'
                                allowColumnReordering={false}
                                allowColumnResizing={false}
                                cacheEnabled={true}
                                columnAutoWidth={false}
                                columnResizingMode='widget'
                                filterSyncEnabled={true}
                                focusedRowEnabled={false}
                                hoverStateEnabled={true}
                                noDataText='No shuttles found.'
                                remoteOperations={true}
                                repaintChangesOnly={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={false}
                                onInitialized={this.gridOnInitialized}
                                onCellClick={this.gridOnCellClick}
                                onCellPrepared={this.gridOnCellPrepared}
                                onRowDblClick={this.gridOnRowDblClick}
                                onToolbarPreparing={this.gridOnToolbarPreparing}
                                onContentReady={this.gridOnContentReady}
                            >
                                <StateStoring enabled={false} type="localStorage" storageKey="storageShuttlesDataGrid" />
                                <Sorting mode='multiple' />
                                <ColumnChooser enabled={false} />
                                <Paging enabled={false} defaultPageSize={20} />
                                <FilterPanel visible={this.state.useAdvancedFilter} />
                                <FilterRow visible={this.state.showFilter} />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} searchVisibleColumnsOnly={true} />
                                <Scrolling
                                    mode="standard"
                                    columnRenderingMode="standard"
                                    rowRenderingMode="standard"
                                    showScrollBar='always'
                                    preloadEnabled={true}
                                />
                                <Column
                                    dataField='id'
                                    dataType='number'
                                    caption='Id'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='eventId'
                                    dataType='number'
                                    caption='EventId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='artistId'
                                    dataType='number'
                                    caption='ArtistId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='locationDepartureId'
                                    dataType='number'
                                    caption='LocationDepartureId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='locationArrivalId'
                                    dataType='number'
                                    caption='LocationArrivalId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='driverId'
                                    dataType='number'
                                    caption='DriverId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='driverColorId'
                                    dataType='number'
                                    caption='Id'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternId'
                                    dataType='number'
                                    caption='PatternId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='vehicleId'
                                    dataType='number'
                                    caption='VehicleId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='statusId'
                                    dataType='number'
                                    caption='StatusId'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='colorHex'
                                    dataType='string'
                                    caption='ColorHex'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='foreColorHex'
                                    dataType='string'
                                    caption='ForeColorHex'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternColorHex'
                                    dataType='string'
                                    caption='PatternColorHex'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternCssBackGround'
                                    dataType='string'
                                    caption='PatternCssBackGround'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternCssBackGroundImage'
                                    dataType='string'
                                    caption='PatternCssBackGroundImage'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternCssBackGroundSize'
                                    dataType='string'
                                    caption='PatternCssBackGroundSize'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='patternCssBackGroundPosition'
                                    dataType='string'
                                    caption='PatternCssBackGroundPosition'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    name='ActionColumnCreateUpdate'
                                    caption=''
                                    allowEditing={false}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    width='30px'
                                    alignment='center'
                                    showInColumnChooser={false}
                                    cellRender={this.renderUpdateTemplate}
                                    headerCellRender={this.renderCreateTemplate} />

                                <Column
                                    dataField='dateTimeDeparture'
                                    dataType='datetime'
                                    caption='DateTimeDeparture'
                                    width='0px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    name='ActionColumnDriverSheet'
                                    caption=''
                                    allowFiltering={false}
                                    allowEditing={false}
                                    allowColumnResizing={false}
                                    allowColumnReordering={false}
                                    allowExporting={false}
                                    fixed={false}
                                    showInColumnChooser={false}
                                    visible={true}
                                    width='40px'
                                    alignment='center'
                                    cellRender={this.renderDriverSheetTemplate}
                                />
                                <Column
                                    name='ActionColumnCreateEmail'
                                    caption=''
                                    allowFiltering={false}
                                    allowEditing={false}
                                    allowColumnResizing={false}
                                    allowColumnReordering={false}
                                    allowExporting={false}
                                    fixed={false}
                                    showInColumnChooser={false}
                                    visible={true}
                                    width='40px'
                                    alignment='center'
                                    cellRender={this.renderCreateEmailTemplate}
                                />
                                <Column
                                    dataField='artistName'
                                    dataType='string'
                                    caption='Artist'
                                    minWidth='175px'
                                    width='175px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                    sortIndex={0}
                                    sortOrder='Asc'
                                />
                                <Column
                                    dataField='locationDepartureName'
                                    dataType='string'
                                    caption='Departure'
                                    minWidth='175px'
                                    width='175px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='locationArrivalName'
                                    dataType='string'
                                    caption='Arrival'
                                    minWidth='175px'
                                    width='175px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='vehicleName'
                                    dataType='string'
                                    caption='Vehicle'
                                    minWidth='150px'
                                    width='175px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='statusName'
                                    dataType='string'
                                    caption='Status'
                                    minWidth='150px'
                                    width='175px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='trailer'
                                    dataType='boolean'
                                    caption='Trailer'
                                    minWidth='40px'
                                    width='40px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    name='ActionColumnDelete'
                                    caption=''
                                    allowEditing={false}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    width='30px'
                                    alignment='center'
                                    showInColumnChooser={false}
                                    cellRender={this.renderDeleteTemplate}
                                />
                                <Column
                                    id='columnTimings'
                                    name='columnTimings'
                                    caption=''
                                    allowFiltering={false}
                                    allowEditing={false}
                                    allowColumnResizing={true}
                                    allowColumnReordering={false}
                                    allowExporting={false}
                                    fixed={false}
                                    showInColumnChooser={false}
                                    visible={true}
                                    alignment='center'
                                    width='100%'
                                    cellRender={this.renderTimingsTemplate}
                                    headerCellRender={this.renderTimingsHeader}
                                />
                            </DataGrid>
                            <Tooltip ref={ref => this.refTooltip = ref} position={{ my: 'bottom center', at: 'top center', collision: 'fit flip' }}>
                                <div className='dgs-tooltip-content'>
                                    <div className="dgs-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.artistName : ''}</div>
                                    <div className="dgs-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.locationDepartureName + ' - ' + this.state.toolTipData.locationArrivalName : ''}</div>
                                    <div className="dgs-tooltip-item">{this.state.toolTipData !== null ? this.getTiming() : ''}</div>
                                    <div className="dgs-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.pax : ''}</div>
                                    {/*<div className="sdt-tooltip-item-status">{this.state.toolTipData !== null ? this.state.toolTipData.StatusName : ''}</div>*/}
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="dgs-popover-statusses">
                    <Popover
                        onInitialized={this.popOverStatusses_OnInitialized}
                        showEvent="click"
                        hideEvent="dblclick"
                        position="top"
                        width={150}
                        showTitle={true}
                    >
                        <RadioGroup
                            ref={ref => this.refRadioGroup = ref}
                            items={this.state.tripStatusses}
                            layout="vertical"
                            onInitialized={this.radioGroupPopOverStatusses_Initialized}
                            onValueChanged={this.radioGroupPopOverStatusses_OnValueChanged}
                        />
                    </Popover>
                </div>
                <PopupShuttles
                    ref={ref => this.refPopup = ref}
                    onInitialized={this.popup_onInitialized}
                    onDBError={this.popup_onDBError}
                    onCreate={this.popup_OnCreate}
                    onUpdate={this.popup_OnUpdate}
                />
                <HxPopupExportExcel
                    defaultName='Shuttles'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />
                <PopupPrintDriverSheet
                    ref={ref => this.refPopupPrintDriverSheet = ref}
                    onInitialized={this.popupPrintDriverSheet_OnInitialized}
                />
                <PopupShuttlesEmail
                    ref={ref => this.refPopupShuttlesEmail = ref}
                    onInitialized={this.popupShuttlesEmail_OnInitialized}
                />
            </React.Fragment>
        );

    }
}

export default DataGridShuttles

