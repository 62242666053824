import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item } from 'devextreme-react/data-grid';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { Icon } from '@iconify/react';
import * as dayJS from 'dayjs';

import HxPopupExportExcel from '../../custom-components/hx-popup-excel-export/HxPopupExportExcel'
import HxToast from '../../custom-components/hx-toast/HxToast';

import globalConfig from '../../../config/global.js';
import globalIcons from '../../../config/globalIcons.js';

import PopupProducts from './PopupProducts';

import ServiceRiders from '../../../api/services/ServiceRiders';
import ServiceEvents from '../../../api/services/ServiceEvents';
import ServiceRidersArtists from '../../../api/services/ServiceRidersArtists';
import ServiceUserSettings from '../../../api/services/ServiceUserSettings';

import './DataGridRiders.scss'

// callables
//
// callbacks
//      onInitialized
//      onDBError

//const this.userAuth. = JSON.parse(sessionStorage.getItem('this.userAuth.'));
const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
class DataGridRiders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            gridHeight: 'calc(100vh - 200px)',
            showFilter: false,
            useAdvancedFilter: false,
            gridAutoMode: false,
            hideComments: false,
            showAllComments: false,
            hideBlankComments: false,
            hideBlankProducts: false,
            productColumnWidth: '350px',
            shopColumnWidth: '200px',
            artistName: ''
        }

        this.userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        this.dataSourceGigDates = [];
        this.dataSourceSubArtists = [];
        this.gridInstance = null;
        this.popupExportExcelInstance = null;

        this.artistColorHex = '#FFFFFFFF';
        this.artistForeColorHex = '#000000FF';

        this.selectArtistId = -1;
        this.selectedGigDateIds = [];

        this.hubConnection = null;

        this.doNotCallCustomizeColumns = false;
        this.buttonColor = '#D0D0D0';// '#03a9f4';
        this.updateColumnWidthProductRunning = false;

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();  // callback
        }
    }

    // CALLABLES
    reload = async (artistid, gigDateIds) => {
        this.selectArtistId = artistid;
        this.selectedGigDateIds = gigDateIds;
        await this.loadDataSourceRiders(artistid, gigDateIds, false);

        await setTimeout(async () => {
            this.gridInstance.beginUpdate();
            if (this.state.showAllComments) {
                this.showAllCommentsDoIt(this.state.showAllComments);
            } else if (this.state.hideComments) {
                this.showHideCommentsDoIt(this.state.hideComments);
            } else if (this.state.hideBlankComments) {
                this.showHideBlankCommentsDoIt(this.state.hideBlankComments);
            }
            await this.showHideBlankProductsDoIt(this.state.hideBlankProducts, true);
            this.gridInstance.endUpdate();
        }, 100);
    }

    // EVENTS
    componentDidMount = async () => {
        const userSettingsPages = JSON.parse(localStorage.getItem('userSettingsPages'));
        const gridAutoMode = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'GridAutoMode')[0].valueBit;
        const hideComments = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'HideComments')[0].valueBit;
        const hideBlankComments = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'HideBlankComments')[0].valueBit;
        const hideBlankProducts = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'HideBlankProducts')[0].valueBit;
        const showFilter = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'ShowFilter')[0].valueBit;
        const useAdvancedFilter = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'UseAdvancedFilter')[0].valueBit;
        const productColumnWidth = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'ProductColumnWidth')[0].valueString;
        const shopColumnWidth = userSettingsPages.filter(u => u.page === 'Riders' && u.userSettingName === 'ShopColumnWidth')[0].valueString;

        this.setState({ gridAutoMode: gridAutoMode });
        this.setState({ hideComments: hideComments });
        this.setState({ hideBlankComments: hideBlankComments });
        this.setState({ hideBlankProducts: hideBlankProducts });
        this.setState({ showFilter: showFilter });
        this.setState({ useAdvancedFilter: useAdvancedFilter });
        if (!hideComments && !hideBlankComments) {
            this.setState({ showAllComments: true });
        } else {
            this.setState({ showAllComments: false });
        }
        this.setState({ productColumnWidth: productColumnWidth });
        this.setState({ shopColumnWidth: shopColumnWidth });

        await this.loadDataSourceRiders(-1, "", false);
        this.selectArtistId = -1;
        this.selectedGigDateIds = -1;
        await this.loadDataSourceGigDates();
        this.hubInit();
        window.addEventListener("resize", this.handleResize);

        if (gridAutoMode) {
            this.handleResize();
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const tabsHeight = document.getElementById('div-tabs').offsetHeight;
        const tagsHeight = document.getElementById('div-tags').offsetHeight;
        const headerHeight = document.getElementById('div-header').offsetHeight;
        const newHeight = window.innerHeight - headerHeight - tabsHeight - tagsHeight - 71;

        document.getElementById('gridRiders').style.height = `${newHeight}px`;

        const gridHeight = window.innerHeight - headerHeight - tabsHeight - tagsHeight - (this.state.useAdvancedFilter ? 53 : 0) - (this.state.showFilter ? 32 : 0) - 56 - 120 - 20 - 33;
        const rows = Math.floor(gridHeight / 31);
        this.gridInstance.option('paging.pageSize', rows);
    }

    // HUB
    hubInit = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(globalConfig.settings.apihost + "/ridersHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessageAmount", await this.hubReceiveMessageAmount);
        this.hubConnection.on("ReceiveMessageComment", await this.hubReceiveMessageComment);
    }

    hubReceiveMessageAmount = async (gigDateId, subArtistId, productId, amount) => {
        try {
            // user is not viewing or editing the subartist the get out of here
            const col = this.gridInstance.getVisibleColumns().filter(c => c.dataField && c.dataField.startsWith('_SUBARTIST_') && c.dataField.toString().replace('_SUBARTIST_', '').split('_')[0] === subArtistId.toString() && c.dataField.endsWith('Amount'));
            if (col.length === 0) {
                return;
            }
        } catch (e) {
            console.log('col: error');
        }

        const dsRow = this.state.dataSourceRiders.find(row => row.productId === productId);
        if (dsRow) {
            Object.keys(dsRow).some(key => {
                if (key.startsWith(`_SUBARTIST_${subArtistId}`) && key.endsWith('Amount') && dsRow[key] !== amount) {
                    dsRow[key] = amount;

                    const row = this.gridInstance.getVisibleRows().find(r => r.data.productId === productId);
                    if (row) {
                        this.gridInstance.cellValue(row.rowIndex, key, amount);
                        this.gridInstance.repaintRows(row.rowIndex);
                        const cellElement = this.gridInstance.getCellElement(row.rowIndex, key);
                        cellElement?.classList.remove('dx-cell-modified');
                    }
                    return true; // Exit loop
                }
                return false;
            });
        }


        //let isEditing2 = this.gridInstance.element().querySelector(".dx-datagrid-rowsview .dx-data-row > td:focus") != null;
        //let dd = this.gridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused");
        //let isEditing = this.gridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        //if (!isEditing) {
        //    const dsRow = this.state.dataSourceRiders.find(row => row.productId === productId);
        //    if (dsRow) {
        //        Object.keys(dsRow).some(key => {
        //            if (key.startsWith(`_SUBARTIST_${subArtistId}`) && key.endsWith('Amount') && dsRow[key] !== amount) {
        //                dsRow[key] = amount;

        //                const row = this.gridInstance.getVisibleRows().find(r => r.data.productId === productId);
        //                if (row) {
        //                    this.gridInstance.cellValue(row.rowIndex, key, amount);
        //                    this.gridInstance.repaintRows(row.rowIndex);
        //                    const cellElement = this.gridInstance.getCellElement(row.rowIndex, key);
        //                    cellElement?.classList.remove('dx-cell-modified');
        //                }
        //                return true; // Exit loop
        //            }
        //            return false;
        //        });
        //    }
            
        //    //setTimeout(this.gridFocus, 10);
        //}
    }

    hubReceiveMessageComment = async (gigDateId, subArtistId, productId, comment) => {
        try {
            // user is not viewing or editing the subartist the get out of here
            const col = this.gridInstance.getVisibleColumns().filter(c => c.dataField && c.dataField.startsWith('_SUBARTIST_') && c.dataField.toString().replace('_SUBARTIST_', '').split('_')[0] === subArtistId.toString() && c.dataField.endsWith('Comment'));
            if (col.length === 0) {
                return;
            }
        } catch (e) {
            console.log('col: error');
        }

        const dsRow = this.state.dataSourceRiders.find(row => row.productId === productId);
        if (dsRow) {
            Object.keys(dsRow).some(key => {
                if (key.startsWith(`_SUBARTIST_${subArtistId}`) && key.endsWith('Comment') && dsRow[key] !== comment) {
                    dsRow[key] = comment;

                    const row = this.gridInstance.getVisibleRows().find(r => r.data.productId === productId);
                    if (row) {
                        this.gridInstance.cellValue(row.rowIndex, key, comment);
                        this.gridInstance.repaintRows(row.rowIndex);
                        const cellElement = this.gridInstance.getCellElement(row.rowIndex, key);
                        cellElement?.classList.remove('dx-cell-modified');
                    }
                    return true; // Exit loop
                }
                return false;
            });
        }
    }

    hubSendUpdateAmount = async (gigDateId, subArtistId, productId, amount) => {
        this.hubConnection
            .invoke("SendUpdateAmount", gigDateId, subArtistId, productId, amount)
            .catch(async function (err) {
                //await this.loadDataSourceProducts();
                return console.error(err.toString());
            });
    }

    hubSendUpdateComment = async (gigDateId, subArtistId, productId, comment) => {
        this.hubConnection
            .invoke("SendUpdateComment", gigDateId, subArtistId, productId, comment)
            .catch(async function (err) {
                //await this.loadDataSourceProducts();
                return console.error(err.toString());
            });
    }

    // DATA
    loadDataSourceRiders = async (artistId, gigDateIds, showLoader) => {
        if (showLoader !== undefined && showLoader === true) {
           this.gridInstance.beginCustomLoading();
        }
        this.gridInstance.beginUpdate();

        this.removeSubArtistColumns();

        var data = await ServiceRiders.getRidersInfoByArtistIdGigDateIds(artistId, gigDateIds);
        if (data !== null && !data.hasOwnProperty('error')) {
            if (data.length > 0) {
                await this.loadDataSourceSubArtists(data[0].artistId)
                this.setState({ artistName: data[0].artistName });
                this.artistColorHex = data[0].artistColorHex;
                this.artistForeColorHex = data[0].artistForeColorHex;
                
                this.addSubArtistColumns(data[0]);
            }
            this.setState({ dataSourceRiders: data });
          } else {
            this.onDBError('Database error!');  // callback
        }

        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.endCustomLoading();
        }

        this.gridInstance.repaint();

        this.gridInstance.endUpdate();
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceEvents.getEventGigDatesDropDown(false);
        if (data !== null && !data.hasOwnProperty("error")) {
            let ds = [];
            for (var i = 0; i < data.length; i++) {
                let myDate = dayJS(data[i].gigDate).format('ddd DD/MM/YY');
                ds.push({ id: data[i].id, text: myDate })
            }
            this.dataSourceGigDates = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceSubArtists = async (artistId) => {
        var data = await ServiceRidersArtists.getSubArtistNamesByArtistId(artistId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceSubArtists = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'locationName' || e.column.dataField === 'productName') {
                e.cellElement.style.backgroundColor = e.data.productColorHex;
                e.cellElement.style.color = e.data.productForeColorHex;
            }
        } else if (e.rowType === 'header') {
            if ((e.column.key !== undefined && e.column.key.startsWith('band')) || (e.column.key !== undefined && e.column.key.startsWith("_SUBARTIST_"))) {
                e.cellElement.style.backgroundColor = this.artistColorHex;
                e.cellElement.style.color = this.artistForeColorHex;
            }
        } 
    }

    gridOnOptionChanged = async (e) => {
        if (e.name === "columns") {
            if (e.fullName.endsWith("columns[6].width") && !this.updateColumnWidthProductRunning) {
                this.updateColumnWidthProductRunning = true;
                setTimeout(() => {
                    const colWidth = this.gridInstance.columnOption('productName', 'width');
                    this.updateColumnWidth('ProductColumnWidth', colWidth.toString() + 'px');
                    this.updateColumnWidthProductRunning = false;
                }, 3000);
            } else if (e.fullName.endsWith("columns[7].width") && !this.updateColumnWidthShopRunning) {
                this.updateColumnWidthShopRunning = true;
                setTimeout(() => {
                    const colWidth = this.gridInstance.columnOption('locationName', 'width');
                    this.updateColumnWidth('ShopColumnWidth', colWidth.toString() + 'px');
                    this.updateColumnWidthShopRunning = false;
                }, 3000);
            }

        } 
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.excel,
                onClick: this.exportRider
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.filter,
                onClick: this.switchFilter,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.showFilter ? this.buttonColor : '#ffffff';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.advanced_filter,
                onClick: this.switchAdvancedFilter,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.useAdvancedFilter ? this.buttonColor : '#ffffff';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.riders_comments,
                elementAttr: { id: 'btnHideComments' },
                onClick: this.showHideComments,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.hideComments ? this.buttonColor : '#ffffff';
                    e.element.style.marginLeft = '20px';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.riders_blank_comments,
                elementAttr: { id: 'btnHideBlankComments' },
                onClick: this.showHideBlankComments,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.hideBlankComments ? this.buttonColor : '#ffffff';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.riders_show_all_comments,
                elementAttr: { id: 'btnShowAllComments' },
                onClick: this.showAllComments,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.showAllComments ? this.buttonColor : '#ffffff';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.compress,
                elementAttr: { id: 'btnHideBlankProducts' },
                onClick: this.showHideBlankProducts,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.hideBlankProducts ? this.buttonColor : '#ffffff';
                    e.element.style.marginLeft = '20px';
                }
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.paging,
                elementAttr: { id: 'btnGridAutoMode' },
                onClick: this.setGridAutoMode,
                onContentReady: (e) => {
                    e.element.style.backgroundColor = this.state.gridAutoMode ? this.buttonColor : '#ffffff';
                }
            },
            location: 'after'
        });
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow' && e.editorName === "dxNumberBox") {
            e.editorOptions.step = 0;
            e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnFocusedCellChanging = (e) => {
        let elements = document.getElementsByClassName("hx-datagrid-cell-border");
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hx-datagrid-cell-border');
            }
        }

        e.cellElement[0].classList.add('hx-datagrid-cell-border');
    }

    gridOnRowUpdating = async (e) => {
        this.gridInstance.beginUpdate();

        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key.startsWith('_SUBARTIST_')) {
                    let colName = key.replace('_SUBARTIST_', '');
                    let gigDateId = parseInt(colName.split("_")[2], 10);
                    let subArtistId = parseInt(colName.split("_")[0], 10);
                    let productId = e.oldData.productId;
                    let amount = e.newData[key] ?? 0;
                    if (key.endsWith('_Amount')) {
                        const modelAmount = {
                            EventId: userSettingsGeneral.currentEventId,
                            GigDateId: gigDateId,
                            SubArtistId: subArtistId,
                            ProductId: productId,
                            Amount: amount,
                            Comment: ''
                        }
                        var data = await ServiceRiders.updateRiderAmount(modelAmount);
                        if (data !== null && !data.hasOwnProperty("error") && data === true) {
                            await this.hubSendUpdateAmount(gigDateId, subArtistId, productId, amount);
                        } else {
                            this.onConnectionError();  // callback
                        }
                    } else if (key.endsWith('_Comment')) {
                        let comment = e.newData[key];
                        const modelComment = {
                            EventId: userSettingsGeneral.currentEventId,
                            GigDateId: gigDateId,
                            SubArtistId: subArtistId,
                            ProductId: productId,
                            Amount: 0,
                            Comment: comment.trim(),
                        }
                        var dataComment = await ServiceRiders.updateRiderComment(modelComment);
                        if (dataComment !== null && !dataComment.hasOwnProperty("error") && dataComment === true) {
                            await this.hubSendUpdateComment(gigDateId, subArtistId, productId, comment);
                        } else {
                            this.onConnectionError();  // callback
                        }
                    }
                }
                break;
            }
        }

        this.gridInstance.endUpdate();
    }

    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'header') {
            let color = '#000000';
            let band = true;
            //e.cellElement.style.backgroundColor = this.artistColorHex;
            //e.cellElement.style.color = this.artistForeColorHex;
            //for (var i = 0; i < e.cellElement.classList.length; i++) {
            //    if (e.cellElement.classList[i].startsWith('hx-column-band-style-')) {
            //        const el = e.cellElement.classList[i];
            //        const ind = el.split('-');
            //        if (ind.length > 0) {
            //            color = '#' + ind[ind.length - 1];
            //        }
            //        break;
            //    } else if (e.cellElement.classList[i].startsWith('hx-column-style-')) {
            //        band = false;
            //        const el = e.cellElement.classList[i];
            //        const ind = el.split('-');
            //        if (ind.length > 0) {
            //            color = '#' + ind[ind.length - 1];
            //        }

            //        break;
            //    }
            //}

            //if (band) {
            //    e.cellElement.childNodes[0].classList.remove('dx-datagrid-text-content');
            //    if (e.eventType == 'mouseover') {
            //        if (color.toUpperCase() == '#000000') {
            //            e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-black');
            //        } else {
            //            e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-white');
            //        }
            //    }

            //} else {
            //    if (e.eventType == 'mouseover') {
            //        e.cellElement.childNodes[1].classList.remove('dx-datagrid-text-content');
            //        if (color.toUpperCase() == '#000000') {
            //            e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-black');
            //        } else {
            //            e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-white');
            //        }
            //    }
            //}
        }
    }

    addSubArtistColumns = (productRow) => {
        this.doNotCallCustomizeColumns = true;

        const entries = Object.entries(productRow);
        const keys = Object.keys(productRow);
        const lastKey = keys[keys.length - 1];

        for (const [key, value] of entries) {
            if (key.startsWith('_SUBARTIST_')) {
                if (key.endsWith('Amount')) {
                    // remove dummy col first or it will be added multiple times
                    this.gridInstance.deleteColumn('productcoldummy');

                    this.gridInstance.addColumn({ key: key, dataField: key, dataType: 'number', caption: 'Amount', allowEditing: true, allowResizing: false, allowfiltering: false, width: '100px' });
                } else {
                    // remove dummy col first or it will be added multiple times
                    this.gridInstance.deleteColumn('productcoldummy');

                    // only call customizeColumns once on last key (performance boost)
                    if (key === lastKey) {
                        this.doNotCallCustomizeColumns = false;
                    }

                    this.gridInstance.addColumn({ key: key, dataField: key, dataType: 'string', caption: 'Comment', allowEditing: true, allowResizing: false, allowfiltering: false, width: '150px' });

                    if (key === lastKey) {
                        this.doNotCallCustomizeColumns = true;
                    }
                }
            }
        }

        this.doNotCallCustomizeColumns = false;
     }

    removeSubArtistColumns = () => {
        let cols = this.gridInstance.getVisibleColumns();

        this.doNotCallCustomizeColumns = true;  // deleteColumns triggers gridCustomizeColumns, we don't want that here

        this.gridInstance.deleteColumn('productcoldummy');

        for (let col of cols) {
            if (col.key !== undefined && (col.key.startsWith('_SUBARTIST_') || col.key.startsWith('band'))) {
                this.gridInstance.deleteColumn(col.key);
            }
        }

        this.doNotCallCustomizeColumns = false;
    }

    gridCustomizeColumns = (columns) => {
        let colAmount = {};

        if (this.doNotCallCustomizeColumns === true)
            return;

        for (let col of columns) {
             if (col.dataField !== undefined && col.dataField.startsWith('_SUBARTIST_')) {
                if (col.dataField.endsWith('Amount')) {
                    colAmount = col;
                } else {
                    const colName = 'band-' + col.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                    const colSubArtistId = parseInt(col.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[0], 10);
                    const gigDateId = parseInt(col.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                    const gigDate = this.dataSourceGigDates.find(x => x.id === gigDateId);
                    const d = new Date(gigDate.gigDate);
                    const formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');
                    const sub = this.dataSourceSubArtists.find(s => s.id === colSubArtistId);
                    const subCaption = sub ? `${sub.name} ${sub.tag} ${formattedGigDate}` : '';

                    columns.push({
                        key: colName,
                        caption: subCaption,
                        isBand: true
                    });

                    colAmount.ownerBand = columns.length - 1;
                    col.ownerBand = columns.length - 1;

                    colAmount = {};
                }
            }
        }
        columns.push({ key: 'productcoldummy', name: 'productcoldummy', caption: '', allowFiltering: false, allowResizing: true, allowExporting: false, width: '100%' });
    }

    updateColumnWidth = async (userSettingName, width) => {

        // set state
        if (userSettingName === 'ProductColumnWidth') {
            this.setState({ productColumnWidth: width });
        } else if (userSettingName === 'ShopColumnWidth') {
            this.setState({ shopColumnWidth: width });
        } else {
            return;
        }

        // update user setting
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: userSettingName,
            valueBit: null,
            valueInt: null,
            valueDecimal: null,
            valueString: width,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

    };

    // POPUP EXPORT
    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.exportToExcel(fileName);
        }
    }

    exportRider = () => {
        this.popupExportExcelInstance.show();
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Product', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }
        });

        worksheet.pageSetup.margins = {
            left: 0.3, right: 0.3,
            top: 0.3, bottom: 0.3,
            header: 0.3, footer: 0.3
        };

        exportDataGrid({
            component: this.gridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: true,
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
        });
    }

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header') {
            excelCell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        }
    }

    // FUNCTIONS
    switchFilter = async (e) => {
        const val = !this.state.showFilter;
    
        // set bg color
        e.element.style.backgroundColor = val ? this.buttonColor : '#ffffff';

        this.setState({ showFilter : val});

        // update user setting
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "ShowFilter",
            valueBit: val,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

        this.handleResize();
    }

    switchAdvancedFilter = async (e) => {
        const val = !this.state.useAdvancedFilter;

        // set bg color
        e.element.style.backgroundColor = val ? this.buttonColor : '#ffffff';

        // set state
        this.setState({ useAdvancedFilter: val});

        // update user setting
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "UseAdvancedFilter",
            valueBit: val,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

        this.handleResize();
        this.gridInstance.repaint();
    }

    showHideComments = async () => {
        if (!this.state.dataSourceRiders?.length || this.state.hideComments) return;

        // set state
        this.setState(prevState => ({
            hideComments: true,
            hideBlankComments: false,
            showAllComments: false
        }));

        // set bg color buttons
        var btnHideComments = document.getElementById('btnHideComments');
        btnHideComments.style.backgroundColor = this.buttonColor;
        var btnHideBlankComments = document.getElementById('btnHideBlankComments');
        btnHideBlankComments.style.backgroundColor = '#ffffff';
        var btnShowAllComments = document.getElementById('btnShowAllComments');
        btnShowAllComments.style.backgroundColor = '#ffffff';

        // set column widths
        this.showHideCommentsDoIt(true);

        // update user settings
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideComments",
            valueBit: true,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

        const newDataBC = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideBlankComments",
            valueBit: false,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdateBC = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newDataBC);
        if (resultUpdateBC.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }
    };

    showHideCommentsDoIt = async (val) => {
        // set column widths
        if (val) {
            const cols = this.gridInstance.getVisibleColumns();
            cols.forEach(col => {
                if (col.dataField?.endsWith('Comment')) {
                    const df = col.dataField.replace('Comment', 'Amount')
                    const colAmount = cols.find(c => c.dataField === df);
                    this.gridInstance.columnOption(colAmount.dataField, "width", '250px');
                    this.gridInstance.columnOption(col.dataField, "width", '0');
                }
            });
        }
    }




    showHideBlankComments = async () => {
        if (!this.state.dataSourceRiders?.length || this.state.hideBlankComments) return;

        // set state
        this.setState(prevState => ({
            hideComments: false,
            hideBlankComments: true,
            showAllComments: false
        }));

        // set bg color buttons
        var btnHideComments = document.getElementById('btnHideComments');
        btnHideComments.style.backgroundColor = '#ffffff';
        var btnHideBlankComments = document.getElementById('btnHideBlankComments');
        btnHideBlankComments.style.backgroundColor = this.buttonColor;
        var btnShowAllComments = document.getElementById('btnShowAllComments');
        btnShowAllComments.style.backgroundColor = '#ffffff';

        // set column widths
        this.showHideBlankCommentsDoIt(true);

        // update user settings
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideBlankComments",
            valueBit: true,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

        const newDataC = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideComments",
            valueBit: false,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdateC = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newDataC);
        if (resultUpdateC.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }
    };

    showHideBlankCommentsDoIt = async (val) => {
        // set column widths
        if (val) {
            // set column widths
            const data = this.state.dataSourceRiders;
            const productRow = data[0];
            Object.keys(productRow).forEach(key => {
                if (key.endsWith('Comment')) {
                    const colAmount = key.replace('Comment', 'Amount');
                    const hasNonBlankComments = data.some(r => r[key]?.trim());
                    this.gridInstance.columnOption(colAmount, "width", !hasNonBlankComments ? '250px' : '100px');
                    this.gridInstance.columnOption(key, "width", !hasNonBlankComments ? '0' : '150px');
                }
            });
        }
    }

    showAllComments = async () => {
        if (!this.state.dataSourceRiders?.length || this.state.showAllComments) return;

        // set state
        this.setState(prevState => ({
            hideComments: false,
            hideBlankComments: false,
            showAllComments: true
        }));

        // set bg color buttons
        var btnHideComments = document.getElementById('btnHideComments');
        btnHideComments.style.backgroundColor = '#ffffff';
        var btnHideBlankComments = document.getElementById('btnHideBlankComments');
        btnHideBlankComments.style.backgroundColor = '#ffffff';
        var btnShowAllComments = document.getElementById('btnShowAllComments');
        btnShowAllComments.style.backgroundColor = this.buttonColor;

         // set column widths
        this.showAllCommentsDoIt(true);

        // update user settings
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideComments",
            valueBit: false,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }

        const newDataBC = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideBlankComments",
            valueBit: false,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdateBC = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newDataBC);
        if (resultUpdateBC.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }
    };

    showAllCommentsDoIt = async (val) => {
        // set column widths
        if (val) {
            const cols = this.gridInstance.getVisibleColumns();
            cols.forEach(col => {
                if (col.dataField?.endsWith('Comment')) {
                    const df = col.dataField.replace('Comment', 'Amount')
                    const colAmount = cols.find(c => c.dataField === df);
                    this.gridInstance.columnOption(colAmount.dataField, "width", '100px');
                    this.gridInstance.columnOption(col.dataField, "width", '150px');
                }
            });
        }
    }

    showHideBlankProducts = async () => {
        if (!this.state.dataSourceRiders?.length) return;

        const val = !this.state.hideBlankProducts;

        // set state
        this.setState({ hideBlankProducts: val });

        // set bg color button
        var btnHideBlankProducts = document.getElementById('btnHideBlankProducts');
        btnHideBlankProducts.style.backgroundColor = val ? this.buttonColor : '#ffffff';

        // show/hide blank products
        await this.showHideBlankProductsDoIt(val);

        // update user setting
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "HideBlankProducts",
            valueBit: val,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }
    }

    showHideBlankProductsDoIt = async (val, doNotReload = false) => {
        // hide blank products
        if (val) {
            const adaptedData = this.state.dataSourceRiders.filter(row =>
                Object.entries(row).some(([col, value]) =>
                    (col.endsWith('_Amount') && value !== null && value !== 0) ||
                    (col.endsWith('_Comment') && value?.trim())
                )
            );
            this.setState({ dataSourceRiders: adaptedData });
        } else {
            if (!doNotReload) {
                await this.loadDataSourceRiders(this.selectArtistId, this.selectedGigDateIds, false);
            }
        }
    }


    setGridAutoMode = async () => {
        const mode = !this.state.gridAutoMode;

        // set state
        this.setState({ gridAutoMode: mode });

        var btnGridAutoMode = document.getElementById('btnGridAutoMode');
        btnGridAutoMode.style.backgroundColor = mode ? this.buttonColor : '#ffffff';

        // recalc rows
        this.handleResize();

        // update user setting
        // update user setting
        const newData = {
            userId: this.userAuth.id,
            page: 'Riders',
            userSettingName: "GridAutoMode",
            valueBit: mode,
            valueInt: null,
            valueDecimal: null,
            valueString: null,
        }
        const resultUpdate = await ServiceUserSettings.updateUsersSettingsPagesUserSetting(newData);
        if (resultUpdate.hasOwnProperty('error')) {
            this.onDBError('Database error!');  // callback
        }
    }

    // RENDERING
    renderArtistName = () => {

        return <div style={{ display: 'flex', justifyContent: 'start', fontSize: '20px', fontWeight: '600' }}>
            <div>{this.state.artistName}</div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <div id='gridRiders'>
                    <DataGrid
                        ref={ref => this.refGrid = ref}
                        dataSource={this.state.dataSourceRiders}
                        keyExpr='id'
                        height='100%'
                        allowColumnReordering={false}
                        allowColumnResizing={false}
                        cacheEnabled={true}
                        columnAutoWidth={false}
                        columnResizingMode='widget'
                        filterSyncEnabled={true}
                        focusedRowEnabled={false}
                        highlightChanges={false}
                        hoverStateEnabled={false}
                        noDataText='No riders found.'
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        customizeColumns={this.gridCustomizeColumns}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onRowUpdating={this.gridOnRowUpdating}
                        onOptionChanged={this.gridOnOptionChanged}
                        onCellHoverChanged={this.gridOnCellHoverChanged}
                    >
                        <StateStoring enabled={false} type="localStorage" storageKey="storageRidersDataGrid" />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <Sorting mode='multiple' />
                        <ColumnChooser enabled={false} />
                        <Paging enabled={true} defaultPageSize={25} />
                        <Pager
                            showPageSizeSelector={!this.state.gridAutoMode}
                            allowedPageSizes={this.allowedPageSizes}
                            showNavigationButtons={true}
                        />
                        <FilterPanel visible={this.state.useAdvancedFilter} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true} searchVisibleColumnsOnly={true} />
                        <Scrolling
                            mode="standard"
                            columnRenderingMode="standard"
                            rowRenderingMode="standard"
                            showScrollBar='always'
                            preloadEnabled={true}
                        />
                        <Toolbar>
                            <Item
                                render={this.renderArtistName}
                                location='before'
                            />
                            <Item name="searchPanel" location='after' />
                        </Toolbar>

                        <Column
                            dataField='id'
                            dataType='number'
                            caption='Id'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='productId'
                            dataType='number'
                            caption='ColorId'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='productColorHex'
                            dataType='string'
                            caption='ProductColorHex'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='productForeColorHex'
                            dataType='string'
                            caption='ProductForeColorHex'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='artistColorHex'
                            dataType='string'
                            caption='ArtistColorHex'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='artistForeColorHex'
                            dataType='string'
                            caption='ArtistForeColorHex'
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='productName'
                            dataType='string'
                            caption='Product'
                            width={this.state.productColumnWidth}
                            allowEditing={false}
                            allowExporting={true}
                            allowFiltering={true}
                            allowHeaderFiltering={true}
                            allowResizing={true}
                            allowSorting={true}
                            fixed={true}
                            showInColumnChooser={true}
                        />
                        <Column
                            dataField='locationName'
                            dataType='string'
                            caption='Shop'
                            width={this.state.shopColumnWidth}
                            allowEditing={false}
                            allowExporting={true}
                            allowFiltering={true}
                            allowResizing={true}
                            allowHeaderFiltering={true}
                            allowSorting={true}
                            cssClass='wrapped-column-class'
                            fixed={true}
                            showInColumnChooser={true}
                        />
                    </DataGrid>
                </div>
                <PopupProducts
                    ref={ref => this.refPopup = ref}
                    onInitialized={this.popup_onInitialized}
                    onDBError={this.popup_onDBError}
                    onCreate={this.popup_OnCreate}
                    onUpdate={this.popup_OnUpdate}
                />
                <HxPopupExportExcel
                    defaultName='Product'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />
            </React.Fragment>
        );

    }
}

export default DataGridRiders

